<template>
  <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_13204_77076)">
      <path d="M23.8827 10.1761L23.8045 12.5792L11.5852 8.71055L11.6626 6.30664L23.8827 10.1761Z" fill="#151213" />
      <path d="M11.6627 6.30664L11.5853 8.71055L0.117432 12.0957L0.195648 9.69263L11.6627 6.30664Z" fill="#1E1A1B" />
      <path d="M12.4149 13.5609L12.3375 15.9648L0.117432 12.0945L0.195648 9.69141L12.4149 13.5609Z" fill="#989898" />
      <path
        d="M11.6626 6.30664L23.8827 10.1761L12.4148 13.5621L0.195557 9.69263L11.6626 6.30664Z"
        fill="url(#paint0_linear_13204_77076)"
        class="group-hover:fill-[url(#paint3_linear_13204_77076)] dark:fill-[url(#paint3_linear_13204_77076)] dark:group-hover:fill-[url(#paint0_linear_13204_77076)]"
      />
      <path d="M23.8827 10.1758L23.8044 12.5788L12.3374 15.9657L12.4148 13.5618L23.8827 10.1758Z" fill="#DADADA" />
      <path
        d="M12.419 13.5976L12.3407 16.0007L0 12.0934L0.0782166 9.68945L12.419 13.5976Z"
        fill="url(#paint1_linear_13204_77076)"
        fill-opacity="0.85"
        class="group-hover:fill-[url(#paint4_linear_13204_77076)] dark:fill-[url(#paint4_linear_13204_77076)] dark:group-hover:fill-[url(#paint1_linear_13204_77076)]"
      />
      <path
        d="M24.0001 10.1777L23.9219 12.5808L12.3408 16.0004L12.419 13.5974L24.0001 10.1777Z"
        fill="url(#paint2_linear_13204_77076)"
        fill-opacity="0.85"
        class="group-hover:fill-[url(#paint5_linear_13204_77076)] dark:fill-[url(#paint5_linear_13204_77076)] dark:group-hover:fill-[url(#paint2_linear_13204_77076)]"
      />
      <path
        d="M0.0791016 9.68915L11.6601 6.26953L24.0009 10.1777L12.419 13.5973L0.0791016 9.68915ZM23.8836 10.1751L11.6643 6.30737L0.195596 9.69168L12.4157 13.5595L23.8827 10.1735"
        fill="#191717"
      />
      <path d="M18.5823 5.61833L17.667 5.42578H17.672L18.5873 5.61749H18.5815" fill="#151313" />
      <path d="M13.5098 5.65448L12.7268 5.45605L17.6669 5.42578L18.5822 5.61833L13.5098 5.65448Z" fill="#DCDCDC" />
      <path
        d="M13.4516 5.67839L12.6694 5.47911L12.6761 5.47491H12.6827H12.6902L12.6977 5.4707L13.4807 5.66914L13.4724 5.67334H13.4649H13.4583H13.4516"
        fill="#141112"
      />
      <path
        d="M13.4808 5.66132L12.6978 5.46289H12.7044H12.7111H12.7186H12.7269L13.5099 5.66132H13.5016H13.4941H13.4866H13.4808Z"
        fill="#141213"
      />
      <path d="M9.58244 8.87991L8.90845 8.5789L12.6695 5.48047L13.4508 5.67974L9.58244 8.87991Z" fill="#DCDCDC" />
      <path
        d="M9.52756 8.88975L8.85522 8.58789H8.86022H8.86521H8.87853L9.55168 8.88975H9.53255H9.52756Z"
        fill="#151313"
      />
      <path d="M9.55158 8.89365L8.87842 8.5918H8.89257L9.56656 8.89281H9.55574" fill="#141213" />
      <path d="M9.5651 8.8889L8.89111 8.58789L9.5651 8.8889Z" fill="#141112" />
      <path d="M9.57829 8.88305L8.9043 8.58203L9.57829 8.88305Z" fill="#131111" />
      <path d="M11.5487 0.0277471L10.814 0L16.0295 0.168164L16.9074 0.200116L11.5487 0.0277471Z" fill="#141112" />
      <path d="M8.34916 10.4288L7.71094 10.0782H7.70679V10.0723L8.34499 10.422V10.4279V10.433H8.34916" fill="#121011" />
      <path d="M6.82738 5.06007L6.22827 4.87424L10.8139 0L11.5487 0.0277471L6.82738 5.06007Z" fill="#C4C4C4" />
      <path d="M8.33513 10.411L7.69691 10.0612L6.22827 4.875L6.82738 5.06082L8.33513 10.411Z" fill="#C4C4C4" />
      <path
        d="M11.5496 0.0273438L16.9074 0.199712C16.9135 0.199708 16.9193 0.201784 16.9241 0.205599C16.9288 0.208845 16.9323 0.213575 16.9341 0.219051C16.9361 0.224758 16.9361 0.231002 16.9341 0.236709C16.9332 0.24291 16.9306 0.248734 16.9266 0.253525L9.51097 8.79964C9.50286 8.80849 9.49763 8.81964 9.496 8.83159C9.49467 8.84196 9.4964 8.85249 9.50099 8.86186C9.50501 8.87095 9.51169 8.87858 9.52012 8.88372C9.5281 8.88962 9.53771 8.89285 9.54759 8.89297H9.55674H9.56506L9.57421 8.88793L9.58253 8.88204L13.4518 5.68187L13.4634 5.6743L13.4759 5.66758L13.4917 5.66169H13.51L18.5858 5.62554C18.5918 5.62531 18.5978 5.62741 18.6024 5.63142C18.6066 5.63497 18.6098 5.63963 18.6115 5.64488C18.6132 5.65064 18.6132 5.65676 18.6115 5.66253C18.6103 5.6688 18.6075 5.67461 18.6032 5.67935L14.1698 10.3266L8.36685 10.4384H8.3577L8.34938 10.4342C8.34676 10.4324 8.3445 10.4301 8.34272 10.4275C8.34028 10.4249 8.33855 10.4217 8.33773 10.4182L6.83081 5.06807L11.5496 0.0273438Z"
        fill="#0D00FF"
        class="group-hover:fill-white dark:fill-white dark:group-hover:fill-[#0D00FF]"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_13204_77076"
        x1="0.787735"
        y1="7.93911"
        x2="23.7186"
        y2="8.54191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0D00FF" />
        <stop offset="1" stop-color="#2BB3FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_13204_77076"
        x1="-14.6847"
        y1="10.0703"
        x2="-5.46405"
        y2="-0.414397"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A01B3" />
        <stop offset="0.338542" stop-color="#1308E1" />
        <stop offset="1" stop-color="#081869" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_13204_77076"
        x1="12.9886"
        y1="15.8842"
        x2="13.5742"
        y2="10.7765"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A01B3" />
        <stop offset="0.338542" stop-color="#1308E1" />
        <stop offset="1" stop-color="#081869" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_13204_77076"
        x1="0.787735"
        y1="7.93911"
        x2="23.7186"
        y2="8.54191"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFFFF" />
        <stop offset="1" stop-color="#2BB3FB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_13204_77076"
        x1="-14.6847"
        y1="10.0703"
        x2="-5.46405"
        y2="-0.414397"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A01B3" />
        <stop offset="0.338542" stop-color="#FFFFFF" />
        <stop offset="1" stop-color="#081869" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_13204_77076"
        x1="12.9886"
        y1="15.8842"
        x2="13.5742"
        y2="10.7765"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A01B3" />
        <stop offset="0.338542" stop-color="#FFFFFF" />
        <stop offset="1" stop-color="#081869" />
      </linearGradient>
      <clipPath id="clip0_13204_77076">
        <rect width="24" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
